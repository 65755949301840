type InstitutionInfo = {
  fullName: string
  appointmentPhone: string
  mssPhone: string
  address: string
  openingHours: readonly { days: string; times: string }[]
  appointmentEmail?: string // appointments and enquiries
  mssEmail: string // FA request submissions
  logo: string
}

// TODO confirm details
export const INSTITUTIONS: Record<string, InstitutionInfo> = {
  // TODO(AH)
  // AH: {
  //   fullName: 'Alexandra Hospital',
  //   phone: '6908 2222',
  //   address: '378 Alexandra Road, Singapore 159964',
  //   openingHours: [
  //     { days: 'Mon - Fri', times: '8.30am to 6pm' },
  //     { days: 'Sat', times: '9am to 2.30pm' },
  //     { days: 'Sun', times: 'Closed' },
  //   ],
  //   logo: '/assets/ah-logo.svg',
  // },
  SGH: {
    fullName: 'Singapore General Hospital',
    appointmentPhone: '6321 4377',
    mssPhone: '6321 4377',
    address:
      'Block 3, Level 1, Medical Social Services, Singapore General Hospital, Singapore 169608',
    openingHours: [
      { days: 'Mon - Fri', times: '8am to 6pm' },
      { days: 'Sat - Sun', times: 'Closed' },
    ],
    mssEmail: 'mss@sgh.com.sg',
    logo: '/assets/sgh-logo.png',
  },
  WH: {
    fullName: 'Woodlands Health',
    appointmentPhone: '6361 6004',
    mssPhone: '6361 6004',
    address:
      'Department of Medical Social Work, Woodlands Health, 17 Woodlands Drive 17, Tower B, Basement 1, Unit 04, Singapore 737628',
    openingHours: [
      { days: 'Mon - Fri', times: '8am to 5pm' },
      { days: 'Sat - Sun', times: 'Closed' },
    ],
    appointmentEmail: 'msw@wh.com.sg',
    mssEmail: 'msw@wh.com.sg',
    logo: '/assets/wh-logo.png',
  },
  TTSH: {
    fullName: 'Tan Tock Seng Hospital',
    appointmentPhone: '6357 8222',
    mssPhone: '6256 6011',
    address: '11 Jalan Tan Tock Seng, Singapore 308433',
    openingHours: [
      { days: 'Mon - Fri', times: '8am to 5pm' },
      { days: 'Sat, Sun and PH', times: 'Closed' },
    ],
    appointmentEmail: 'MSW@ttsh.com.sg',
    mssEmail: 'MSW_Documents@ttsh.com.sg',
    logo: '/assets/ttsh-logo.png',
  },
} as const
